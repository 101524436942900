




































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AuthService } from '@/lib/services';
import { unwrapError, headOrSelf } from '@/lib/helpers';
import Logo from '@/components/Logo.vue';
import { namespace } from 'vuex-class';

const sAuth = namespace('auth');

@Component({
  components: { Logo },
})
export default class TwoFactor extends Vue {
  @sAuth.Getter('isAccountant') isAccountant: boolean;

  public codes = {
    totp: '',
    recovery: '',
  };
  loading: boolean = false;
  recovery: boolean = false;

  mounted(): void {
    if (!AuthService.hasPreloginToken) {
      this.$router.push({ name: 'login' });
    }
  }

  async submit(): Promise<void> {
    this.loading = true;

    try {
      if (await AuthService.login2fa(this.codes.totp, this.codes.recovery)) {
        this.afterLogin();
      }
    } catch (e) {
      this.$toaster.error('Login failed', unwrapError(e));
      this.loading = false;
    }
  }

  afterLogin(): void {
    if (this.$store.state.settings.subscription.satisfied) {
      if (this.isAccountant) {
        this.$router.push({ name: 'accountant' });
      } else if ('redirect' in this.$route.query) {
        const redirect = headOrSelf(this.$route.query['redirect']);
        if (redirect == null) {
          this.$router.push('/');
        } else {
          this.$router.push(redirect);
        }
      } else {
        this.$router.push('/');
      }
    } else {
      this.$router.push('/confirm-subscription');
    }
  }

  startRecovery(): void {
    this.recovery = true;
    this.codes.totp = '';
  }

  endRecovery(): void {
    this.recovery = false;
    this.codes.recovery = '';
  }
}
